import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/js/bootstrap';
import "bootstrap"
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import '@fortawesome/fontawesome-free/css/all.css';

createApp(App)
    .use(router)
    // .use(Gmap3, {
    //   load: {
    //     key: 'AIzaSyAwj03ysFd-K_vZxJq4QZsioyp0d_mY6Yc',
    //     libraries: 'places', // You can add more libraries if needed
    //   },
    // })
    .mount("#app")
