<template>
  <router-view/>
</template>

<style lang="scss">
//  Ingen aning om varför, men tar man bort dessa så funkar inte .btn.btn-book styling (:
nav {

  a {

    
  }
}
</style>
