<template>
    <div class="wrapper">
      <NavBar ref="navBarRef" :scroll-to-about="scrollAboutIntoView" :scroll-to-services="scrollServicesIntoView" :scroll-to-contact="scrollContactIntoView" />
      <div class="home">
<!--          <div class="container-lg">-->
<!--            <h1>Läkning i harmoni</h1>-->
<!--            <p>Sidan är under uppbyggnad. Välkommen åter!</p>-->
<!--            <p>Kontakt: -->
<!--                <a href="mailto:cecilia.wallen@cewall.se">cecilia.wallen@cewall.se</a>-->
<!--            </p>-->
<!--          </div>-->
      </div>
          <div class="container-lg p-4">
              <AboutSection ref="aboutSectionRef" />
              <hr class="hairline">
              <ServicesSection ref="servicesSectionRef" />
              <hr class="hairline">
              <ContactSection ref="contactSectionRef" />
              <hr class="hairline">
          </div>
    </div>
</template>

<script setup>
  // @ is an alias to /src
  import NavBar from "@/components/NavBar.vue"
  import AboutSection from "@/components/AboutSection.vue"
  import { ref } from "vue"
  import ServicesSection from "@/components/ServicesSection.vue"
  import ContactSection from "@/components/ContactSection.vue"
  
  const servicesSectionRef = ref(null)
  const aboutSectionRef = ref(null)
  const contactSectionRef = ref(null)
  const navBarRef = ref(null)
  
  // TODO: Måste hitta nån tweak för att ta hänsyn till fixed navbar
  const scrollAboutIntoView = (toggle) => {
    aboutSectionRef.value?.headingRef.scrollIntoView({ behavior: 'smooth' })
    if (toggle) {
      navBarRef.value?.toggleNavbar()
    }
  }
  
  const scrollServicesIntoView = (toggle) => {
    servicesSectionRef.value?.headingRef.scrollIntoView({ behavior: 'smooth' })
    if (toggle) {
      navBarRef.value?.toggleNavbar()
    }
  }
  
  const scrollContactIntoView = (toggle) => {
    contactSectionRef.value?.headingRef.scrollIntoView({ behavior: 'smooth' })
    if (toggle) {
      navBarRef.value?.toggleNavbar() 
    }
  }
  
  
</script>
<style scoped lang="scss">
  @import "@/styles/index";
  
  .wrapper {
    background-color: $purple-lightest;
    color: $purple-darkest;
  }
  .home {
    background-image: url("@/assets/priscilla-du-preez-ukZazKwQpec-unsplash.jpg");
    background-position: center;
    background-size: cover;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    
    @media (min-width: 992px) {
      margin-top: 130px;
    }
    
    div {
      backdrop-filter: blur(6px);
      width: fit-content;
      color: $white;
    }
    
    a {
      color: #fff;
    }
    
  }
  
  .hairline {
    margin: 2.5rem 0;
  }
</style>
