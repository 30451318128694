<template>
    <div class="contact-section-wrapper">
        <div class="p-lg-4">
            <div class="row">
                <div class="col-12">
                    <h2 ref="headingRef">Kontakt</h2>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="mb-4">
                        <p class="fw-bold mb-1">Adress</p>
                        <p>Lärjungevägen 6, 136 69 Vendelsö</p>
                        <p class="mb-0">1 trappa upp, dörren till höger efter trappan/hissen.</p>
                        <p class="mb-0">Parkeringmöjligheter finns mot avgift bakom fastigheten.</p>
                    </div>
                    <div class="mb-4">
                        <p class="fw-bold mb-1">Telefon</p>
                        <a href="tel:46723036343">072-303 63 43</a>
                    </div>
                    <div class="mb-4">
                        <p class="fw-bold mb-1">E-post</p>
                        <a href="mailto:info@lakningiharmoni.se">info@lakningiharmoni.com</a>
                    </div>
                    <div class="mb-4">
                        <a href="https://www.instagram.com/lakning.iharmoni?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" class="me-3">
                            <i class="fa-brands fa-instagram fa-2xl"></i>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61557864054936" target="_blank">
                            <i class="fa-brands fa-square-facebook fa-2xl"></i>
                        </a>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <GoogleMaps />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
  import GoogleMaps from "@/components/GoogleMaps.vue"
  import { ref } from "vue"

  const headingRef = ref(null)

  defineExpose({headingRef})
</script>

<style scoped lang="scss">
    h2 {
        scroll-margin-top: 7rem;
        @media (min-width: 992px) {
            scroll-margin-top: 10rem;
        }
    }
</style>