<template>
    <div class="services-section-wrapper">
        <div class="p-lg-4">
            <h2 ref="headingRef">Tjänster</h2>
            <div class="row gy-5">
                <div class="col-12 col-lg-4">
                    <div class="service-card">
                        <div class="row gx-3">
    <!--                        <div class="col-2">-->
    <!--                            <img src="@/assets/195542176_10208933710617488_778481015760600086_n.jpg" alt="Homeopati" class="w-100"/>-->
    <!--                        </div>-->
                            <div class="col-12">
                                <h3>Klassisk Homeopati - nybesök</h3>
                                <p class="mb-1 fw-medium">60-120 min</p>
                                <p class="fw-medium">1395 SEK</p>
                                <p>Första besöket tar mellan 60-120 minuter. Tillsammans går vi igenom din sjukdomshistoria och din nuvarande symtombild samt livssituation.</p>
                            </div>
    <!--                        <div class="col-2 d-flex align-items-center justify-content-end">-->
    <!--                            <button type="button" class="btn btn-book">Boka</button>-->
    <!--                        </div>-->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="service-card">
                      <div class="row gx-3">
  <!--                        <div class="col-2">-->
  <!--                            <img src="@/assets/195542176_10208933710617488_778481015760600086_n.jpg" alt="Homeopati" class="w-100"/>-->
  <!--                        </div>-->
                          <div class="col-12">
                              <h3>Klassisk Homeopati - återbesök</h3>
                              <p class="mb-1 fw-medium">30-60 min</p>
                              <p class="fw-medium">895 SEK</p>
                              <p>Konsultationen tar mellan 30-60 minuter. Vi går igenom vad som hänt sedan sist och gör en ny bedömning på fortsatt läkning</p>
                          </div>
  <!--                        <div class="col-2 d-flex align-items-center justify-content-end">-->
  <!--                            <button type="button" class="btn btn-book">Boka</button>-->
  <!--                        </div>-->
                      </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="service-card">
                        <div class="row gx-3">
    <!--                        <div class="col-2">-->
    <!--                            <img src="@/assets/195542176_10208933710617488_778481015760600086_n.jpg" alt="Homeopati" class="w-100"/>-->
    <!--                        </div>-->
                            <div class="col-12">
                                <h3>Chakrabalansering</h3>
                                <p class="mb-1 fw-medium">60 min</p>
                                <p class="fw-medium">895 SEK</p>
                                <p>Behandlingen tar 45+15 min (60 min). Kom för en avstressande behandling på en kristallbädd med infraröd värme. Under tiden du ligger och njuter, balanserar jag dina chakran med en pendel.</p>
                            </div>
    <!--                        <div class="col-2 d-flex align-items-center justify-content-end">-->
    <!--                            <button type="button" class="btn btn-book">Boka</button>-->
    <!--                        </div>-->
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-center">
                        <a href="https://www.edibooking.com/lakning-i-harmoni" target="_blank" class="btn btn-book">BOKA HÄR</a>
                    </div>
                </div>
<!--                <div class="col-12">-->
<!--                    <iframe src="https://www.edibooking.com/lakning-i-harmoni/embed_mode" style="height:600px;width:100%;border:0;"></iframe>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script setup>
  import { ref } from "vue"

  const headingRef = ref(null)
  
  defineExpose({headingRef})
</script>

<style scoped lang="scss">
  @import "@/styles/index";

  h2 {
    scroll-margin-top: 7rem;
    @media (min-width: 992px) {
      scroll-margin-top: 10rem;
    }
  }
  
  .service-card {
    background-color: $white;
    padding: 0.75rem 0.5rem;
    border-radius: 4px;
    color: $purple;
    height: 100%
  }
</style>