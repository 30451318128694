import { createRouter, createWebHashHistory } from 'vue-router'
import LandingPage from "@/views/LandingPage.vue"
import AboutSection from "@/components/AboutSection.vue"
import ServicesSection from "@/components/ServicesSection.vue"
import ContactSection from "@/components/ContactSection.vue"

const routes = [
  {
    path: "/",
    name: "landingPage",
    component: LandingPage
  },
  // {
  //   path: "/om",
  //   name: "about",
  //   component: AboutSection
  // },
  // {
  //   path: "/tjanster",
  //   name: "services",
  //   component: ServicesSection
  // },
  // {
  //   path: "/kontakt",
  //   name: "contact",
  //   component: ContactSection
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
