<template>
    <div>
        <GoogleMap api-key="AIzaSyAwj03ysFd-K_vZxJq4QZsioyp0d_mY6Yc" :center="center" :zoom="zoom" style="width: 100%; height: 300px">
            <Marker :options="{ position: center }"></Marker>
        </GoogleMap>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { GoogleMap, Marker } from 'vue3-google-map';

    const center = { lat: 59.189568, lng: 18.175985 };
    const zoom = ref(16);
</script>

<style scoped lang="scss">
  
</style>