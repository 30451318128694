<template>
  <div class="about-section-wrapper">
      <div class="p-lg-4">
          <h2 ref="headingRef">Om</h2>
          <div class="row gy-5">
              <div class="col-12">
                  <h3>Klassisk Homeopati</h3>
                  <p>Klassisk homeopati handlar om att hjälpa immunförsvaret att uppmärksamma och läka ut obalanser i kroppen, på alla plan, fysiskt, mentalt och känslomässigt. Med hjälp av homeopatiska läkemedel som tillverkas av ämnen från växt, djur, mineral och vissa syror. Det är en skonsam naturlig behandling. Dessutom miljövänligt framtaget.</p>
                  <p>Homeopati bygger på att lika botar liknande (similia similibus), principen uppstod för 2 500 år sedan på Hippokrates tid. I slutet av 1700-talet kom principen att bli en egen läkekonst skapad av den tyske läkaren Samuel Hahnemann. Hans strävan var att läka hela individen, inte bara symtomlindra. Med homeopatisk läkekonst behandlar vi hela människan (eller djuret). Den hämmar inga symtom tillfälligt utan triggar immunförsvaret för att starta en självläkningsprocess.</p>
                  <div>
                      <p>Grundläggande principer</p>
                      <ul>
                          <li>Det finns inga sjukdomar, det finns sjuka människor</li>
                          <li>Som homeopat ser man till hela individen. Ett lämpligt medel ska passa helhetsbilden på alla tre plan, fysiskt, mentalt och känslomässigt</li>
                          <li>Minsta möjliga dos ska användas</li>
                          <li>
                              <span>Läkningsprocessen sker -</span>
                              <ol>
                                  <li>I omvänd ordning till hur de uppkommit</li>
                                  <li>Inifrån och ut</li>
                                  <li>Uppifrån och ner</li>
                              </ol>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="col-12">
                  <div class="row">
                      <div class="col-12">
                          <h3>Behandling</h3>
                      </div>
                      <div class="col-12 col-md-7 col-lg-9">
                          <p>
                              Vi träffas på vår klinik som ligger i Vendelsö. Tillsammans gör en kartläggning över din sjukdomshistoria (anamnes). Samtalet tar mellan 60-120 minuter. Vi går igenom din nuvarande symtombild och pratar även om dina tidigare symtom och sjukdomar. För att skräddarsy ett medel som passar dig som individ på alla plan är det viktigt att vi noterar allt, fysiska, mentala och känslomässiga besvär. 
                              När vi ses kommer jag även göra en irisdiagnostik för att se hur din kropp och organ mår. Det handlar om totaliteten av dig som individ. För mig som homeopat är det av högsta vikt att du ska känna dig bekväm att dela din historia och jag har tystnadsplikt. 
                              Efter vårt möte repetoriserar jag på dina symtom och kommer fram till bästa lämpliga läkemedel. Eftersom denna process tar en stund postar jag läkemedlet till dig. Det går naturligtvis även att du kommer förbi och hämtar upp läkemedlet någon dag senare.
                          </p>
                          <p>Det går inte att förutspå hur lång tid det tar för kroppen att läka obalansen. Ett akut symtom läker snabbare än ett kroniskt, som behöver mer läkningstid. Immunförsvaret är försvagat och behöver stöttning, med rätt medel sker läkning. Viktigt att ha tålamod.</p>
                          <p>Det kan även initialt bli en ”förstförsämring”, vilket innebär att vissa symtom till en början kan upplevas förstärkta. Detta är en positiv reaktion, då vet man att immunförsvaret har uppfattat obalansen och arbetar för att påbörja självläkningsprocessen.</p>
                          <p>Efter ca fem veckor har vi ett uppföljningssamtal, tar mellan 30-60 minuter. Vi går igenom vad som skett sedan föregående samtal. Vilka symtom har förbättrats och har det dykt upp nya symtom? Det är inte alls ovanligt att när en obalans läker ut dyker ett tidigare symtom upp. Att läka ut kroppens obalanser är som att skala en lök, det sker i lager och vart eftersom immunförsvaret blir starkare.</p>
                          <p>För bästa samtal ses vi på salongen i Vendelsö. Det går även att vi har samtalet digitalt. Dock blir mötet betydligt mer genuint om vi möts fysiskt.</p>
                      </div>
                      <div class="col-12 col-md-5 col-lg-3">
                          <div class="row gy-2">
                              <div class="col-12">
                                  <img src="@/assets/IMG_7976.png" alt="Behandlingsrum" class="w-100"/>
                              </div>
                              <div class="col-12">
                                  <img src="@/assets/IMG_7983.png" alt="Kristallbädd" class="w-100"/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-12">
                  <div class="row">
                      <div class="col-12">
                          <h3>Om mig</h3>
                      </div>
                      <div class="col-12 col-md-7 col-lg-9">
                          <p>
                              Jag heter Cecilia och har med hjälp av homeopati läkt ut flera obalanser som jag led av. Som t.ex pälsdjursallergi, idag har jag min älskade katt som jag kan gosa med, helt obehindrat. Eller pollenallergin, tidigare intog jag antihistamin året runt för att slippa klådan, utslagen och astma, idag känner jag inte av pollen oavsett vilken årstid, helt utan hjälp av antihistamin. Även matintoleranserna har läkt ut. 
                              Jag sökte länge inom den traditionella vården efter bot men upplevde alltid att symtomen kom tillbaka efter avslutad kur. Jag sökte mig därför till den alternativa vården och fann homeopatin. Vilket jag är oerhört tacksam för idag. Det var därför jag bestämde mig för att själv utbilda mig till homeopat, för jag vill hjälpa andra på samma sätt som jag själv blev hjälpt.
                          </p>
                          <p>Jag brinner för att få lösa den mystiska gåtan till varför vi har de symtom vi har.</p>
                          <p>Jag har gått en 3-årig utbildning på Camrénska institutet i Örebro och är diplomerad homeopat. Jag utbildar mig nu även till Irisdiagnostiker vilket är ett fantastiskt hjälpmedel.</p>
                      </div>
                      <div class="col-12 col-md-5 col-lg-3">
                          <img src="@/assets/IMG_7575.jpg" alt="Behandlingsrum" class="w-100"/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script setup>
  import { ref } from "vue"

  const headingRef = ref(null)
  
  defineExpose({headingRef})
</script>

<style scoped lang="scss">
  .about-section-wrapper {
    text-align: start;
  }

  h2 {
      scroll-margin-top: 7rem;
      @media (min-width: 992px) {
          scroll-margin-top: 10rem;
      }
  }
  
  img {
      border-radius: 4px;
  }
</style>