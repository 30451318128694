<template>
    <div ref="el" class="navbar-wrapper">
        <nav class="navbar fixed-top navbar-expand-lg" data-bs-theme="dark">
            <div class="container-lg p-3 p-lg-4">
                <a class="navbar-brand fw-normal mb-0" href="#">Läkning i harmoni</a>
                <button class="navbar-toggler"
                        type="button"
                        @click="toggleNavbar"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="w-100 d-flex flex-column flex-lg-row justify-content-lg-end mt-3 my-lg-0">
                        <div class="navbar-nav mx-4">
                            <button class="nav-link d-lg-none" @click="scrollToTop(true)">Hem</button>
                            <button class="nav-link d-lg-none ms-2" @click="scrollToAbout(true)">Om</button>
                            <button class="nav-link d-lg-none ms-2" @click="scrollToServices(true)">Tjänster</button>
                            <button class="nav-link d-lg-none ms-2" @click="scrollToContact(true)">Kontakt</button>
                            <button class="nav-link d-none d-lg-block" @click="scrollToTop(false)">Hem</button>
                            <button class="nav-link d-none d-lg-block ms-2" @click="scrollToAbout(false)">Om</button>
                            <button class="nav-link d-none d-lg-block ms-2" @click="scrollToServices(false)">Tjänster</button>
                            <button class="nav-link d-none d-lg-block ms-2" @click="scrollToContact(false)">Kontakt</button>
    <!--                        <RouterLink to="/">Hem</RouterLink>-->
    <!--                        <RouterLink to="/om">Om</RouterLink>-->
    <!--                        <RouterLink to="/tjanster">Tjänster</RouterLink>-->
    <!--                        <RouterLink to="/kontakt">Kontakt</RouterLink>-->
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3 mt-lg-none">
                            <a href="https://www.edibooking.com/lakning-i-harmoni" target="_blank" class="btn btn-book">BOKA HÄR</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref } from "vue"
    import Collapse from "bootstrap/js/dist/collapse"
    
    const props = defineProps({
        scrollToAbout: Function,
        scrollToServices: Function,
        scrollToContact: Function
    })

    const collapse = ref(null)
    
    onMounted(() => {
        collapse.value = new Collapse("#navbarNavAltMarkup", {
            toggle: false
        })
        collapse.value.hide()
    })
    
    const toggleNavbar = () => {
        collapse.value.toggle()
    }
    
    const el = ref(null)
    const offsetHeight = computed(() => {
        console.log(el.value.height())
        return el.value.offsetHeight
    })
    
    const scrollToTop = (toggle) => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
        if (toggle) {
            toggleNavbar()
        }
    }
    defineExpose({toggleNavbar})
</script>

<style scoped lang="scss">
    @import "@/styles/index";
    
    .navbar-wrapper {
        /*background-color: rgba(188, 184, 201, 0.8);*/
        //background-color: rgba(242, 241, 244, 0.9);
        background-color: $purple;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15), inset 0 -1px 0 rgba(255,255,255,0.15);
    }
    .navbar {
        background-color: inherit !important;
    }
    
    .navbar-brand {
        color: $purple-lightest;
    }
    
    .nav-link {
        color: $purple-lightest;
        background-image: linear-gradient($white, $white);
        background-size: 0% 0.1em;
        background-position-y: 100%;
        background-position-x: 0%;
        background-repeat: no-repeat;
        transition: background-size 0.2s ease-in-out;
        text-decoration: none;
        
        &:hover {
            background-size: 100% 0.1em;
        }
    }
    
    .navbar-toggler {
        border: none;
    }
</style>